
.footnotes {
    font-size: smaller;
    color: #9c9c9c;
    border-top: 1px solid #30363d;
    margin-top: 5px;
    line-height: 1.8rem;
    padding-top: 5px;
}

.footnotes ol {
    margin-left: 25%;
}

/* Hide the section label for visual users. */
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    word-wrap: normal;
    border: 0;
}

/* Place `[` and `]` around footnote references. */
[data-footnote-ref]::before {
    content: '[';
}

[data-footnote-ref]::after {
    content: ']';
}
