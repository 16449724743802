.body-block {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.body-image, .body-video, .body-audio {
    text-align: center;
    position: relative;
}

.body-block ul {
    margin-left: 5%;
}

.body-block ul li {
    margin-top: 2px;
}

.body-block blockquote {
    margin: 5px 5% 5px 4%;
    color: #c5c5c5;
    font-family: monospace, serif;
    border-left: 2px solid #5c5c5c;
    padding-left: 15px;
    font-size: 1.7rem;
    line-height: 1.7rem;
    text-align: justify;
}

.body-caption {
    text-align: center;
    background: #A50044;
    background: radial-gradient(circle, rgba(165, 0, 68, 1) 0%, rgba(25, 25, 25, 1) 60%);
    margin-top: -5px;
    padding-top: 1px;
    padding-bottom: 1px;
    font-size: 1.3rem;
}

.body-image img, .body-video img, .body-audio img {
    --s: 55%; /* the size of the image */
    width: var(--s);
    outline: calc(var(--s) / 2) solid #0009;
    outline-offset: calc(var(--s) / -2);
    cursor: pointer;
    transition: .3s;
}

.body-image img:hover, .body-video img:hover, .body-audio img:hover {
    outline: 3px solid #A50044;
    outline-offset: 1px;
}

.body-video svg, .body-audio svg {
    color: #A50044;
    position: absolute;
    top: 15px;
    left: 25%;
    width: 36px;
    height: 36px;
}

.body-audio img.small {
    width: 100px;
    margin-bottom: 5px;
}

@media screen and (max-width: 1200px) {
    .body-image img, .body-video img, .body-audio img {
        --s: 65%;
    }

    .body-video svg, .body-audio svg {
        left: 20%;
    }
}


.body-block .source {
    font-style: italic;
    text-align: right;
    color: var(--subtitle-color);
    margin-top: 5px;
}

.body-block .source.bigger {
    font-size: 1.5rem;
    line-height: 1.5rem;
}

.stack {
    margin: 20px auto;
    width: 30vw;
    padding: 0;
    position: relative;
    max-width: 100%;
}

.stack img {
    max-width: 100%;
    width: 30vw;
    max-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s;
    cursor: pointer;
}

.stack img:hover {
    box-shadow: 0px 1px 2px 0px rgb(219, 0, 48, 0.5),
    1px 2px 4px 0px rgb(165, 0, 68, 0.3),
    2px 4px 8px 0px rgb(165, 0, 68, 0.3),
    2px 4px 16px 0px rgb(165, 0, 68, 0.3);
}

.stack img:first-child {
    position: relative;
    z-index: 10;
}

.stack-sideslide img:nth-child(2),
.stack-sideslide img:nth-child(3) {
    opacity: 0.2;
    visibility: visible;
    -webkit-transition: -webkit-transform 0.3s, opacity 0.3s, visibility 0s 0.3s;
    transition: transform 0.3s, opacity 0.3s, visibility 0s 0.3s;
}

.stack-sideslide img:nth-child(2) {
    -webkit-transform: translateX(-50%) scale(0.6);
    transform: translateX(-50%) scale(0.6);
    z-index: 8;
}

.stack-sideslide img:nth-child(3) {
    -webkit-transform: translateX(50%) scale(0.6);
    transform: translateX(50%) scale(0.6);
    z-index: 6;
}

.stack-sideslide:hover img:nth-child(2),
.stack-sideslide:hover img:nth-child(3) {
    opacity: 1;
    visibility: visible;
    -webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
    transition: transform 0.5s, opacity 0.5s;
}

.stack-sideslide:hover img:nth-child(2) {
    -webkit-transform: translateX(-40%) scale(0.8);
    transform: translateX(-40%) scale(0.8);
}

.stack-sideslide:hover img:nth-child(3) {
    -webkit-transform: translateX(40%) scale(0.8);
    transform: translateX(40%) scale(0.8);
}

.stack-footer {
    width: 100%;
    text-align: center;
    background: #A50044;
    background: radial-gradient(circle, rgba(165, 0, 68, 1) 0%, rgba(25, 25, 25, 1) 75%);
    margin-top: -20px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 1.3rem;
}

@media screen and (min-width: 1400px) {
    .stack-footer {
        width: 90%;
    }
}

@media screen and (min-width: 1600px) {
    .stack-footer {
        width: 85%;
    }
}

.tag {
    border-radius: .25rem;
    margin-inline-start: 1rem;
    color: rgb(209, 213, 219);
    background-color: rgb(55, 65, 81);
    font-size: 1.7rem;
    line-height: 1.7rem;
    font-weight: normal;
    padding-bottom: .125rem;
    padding-top: .125rem;
    padding-left: .625rem;
    padding-right: .625rem;
}

.tag.grey {
    color: rgb(209, 213, 219);
    background-color: rgb(55, 65, 81);
}

.tag.blue {
    color: rgb(164, 202, 254);
    background-color: rgb(35, 56, 118);
}

.tag.red {
    color: rgb(248, 180, 180);
    background-color: rgb(119, 29, 29);
}

.tag.green {
    color: rgb(132, 225, 188);
    background-color: rgb(1, 71, 55);
}

.tag.yellow {
    color: rgb(250, 202, 21);
    background-color: rgb(99, 49, 18);
}

.tag.indigo {
    color: rgb(180, 198, 252);
    background-color: rgb(54, 47, 120);
}

.tag.purple {
    color: rgb(202, 191, 253);
    background-color: rgb(74, 29, 150);
}

.tag.pink {
    color: rgb(248, 180, 217);
    background-color: rgb(117, 26, 61);
}
