/*************/
/* NAVIGATION */
/*************/
nav {
    position: fixed;
    top: 2rem;
    right: 0;
    width: 10.5vw;
    z-index: 5;
}

nav .burger-container {
    display: flex;
    justify-content: end;
    margin-right: 10px;
}

nav ul {
    opacity: 1;
    list-style-type: none;
    border: 0 outset #666;
    border-left-width: 1px;
}

nav ul li.close {
    text-align: right;
    padding-right: 15px;
    padding-top: 5px;
}

nav ul li.close button {
    background: transparent;
    border: none;
    color: whitesmoke;
    font-size: 2.5rem;
}

nav ul li.close button:hover {
    cursor: pointer;
}

nav ul li.hide {
    display: none;
}

@media screen and (max-width: 799px) {
    nav {
        width: 125px;
    }

    nav ul {
        background: -webkit-linear-gradient(to right, rgba(25, 25, 25,.8) 0%, #2c2c2c 30%);
        background: -moz-linear-gradient(to right, rgba(25, 25, 25,.8) 0%, #2c2c2c 30%);
        background: -o-linear-gradient(to right, rgba(25, 25, 25,.8) 0%, #2c2c2c 30%);
        background: linear-gradient(to right, rgba(25, 25, 25,.8) 0%, #2c2c2c 30%);
        border-top-width: 1px;
        border-bottom-width: 1px;
        border-radius: 10px 0 0 10px;
        padding: 5px 0;
        position: relative;
        top: -45px;
        right: -130px;
        transition: all 350ms ease-in-out;
        opacity: 0;
    }

    nav.active ul {
        right: 0;
        opacity: 1;
    }

    nav.active .burger-container button {
        opacity: 0;
    }
}

@media screen and (min-width: 800px) {
    nav .burger-container, nav ul li.close {
        display: none;
    }
}

nav li.is-current > a {
    color: #ccc;
    font-weight: 500;
}

nav a {
    text-decoration: none;
    display: block;
    padding: .125rem 0;
    color: #555;
    transition: all 50ms ease-in-out;
}

nav a:hover, nav a:focus {
    color: #999;
}

/*************/
/* NAVIGATOR CHECKBOX */
/*************/
.check-nav {
    --text: #f5f5f5;
    --check: #A50044;
    --disabled: #666666;
    --border-radius: 10px;
    border-radius: var(--border-radius);
    position: relative;
    padding: 5px 0px 5px 5px;
    display: grid;
    grid-template-columns: 30px auto;
    align-items: center;
}

.check-nav label p {
    word-break: break-word;
    word-wrap: break-word;
}

.check-nav label span {
    opacity: .4;
}

.check-nav label {
    color: var(--disabled);
    position: relative;
    cursor: pointer;
    display: grid;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    transition: color 0.3s ease;
}

.check-nav label::before,
.check-nav label::after {
    content: "";
    position: absolute;
}

.check-nav label::before {
    height: 2px;
    width: 8px;
    left: -27px;
    background: var(--check);
    border-radius: 2px;
    transition: background 0.3s ease;
}

.check-nav label:after {
    height: 4px;
    width: 4px;
    top: 8px;
    left: -25px;
    border-radius: 50%;
}

.check-nav input[type=checkbox] {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    height: 15px;
    width: 15px;
    outline: none;
    border: 0;
    margin: 0 15px 0 0;
    cursor: pointer;
    background: var(--background);
    display: grid;
    align-items: center;
}

.check-nav input[type=checkbox]::before, .check-nav input[type=checkbox]::after {
    content: "";
    position: absolute;
    height: 2px;
    top: auto;
    background: var(--check);
    border-radius: 2px;
}

.check-nav input[type=checkbox]::before {
    width: 0px;
    right: 60%;
    transform-origin: right bottom;
}

.check-nav input[type=checkbox]::after {
    width: 0px;
    left: 40%;
    transform-origin: left bottom;
}

.check-nav input[type=checkbox]:checked::before {
    -webkit-animation: check-anim-01 0.4s ease forwards;
    animation: check-anim-01 0.4s ease forwards;
}

.check-nav input[type=checkbox]:checked::after {
    -webkit-animation: check-anim-02 0.4s ease forwards;
    animation: check-anim-02 0.4s ease forwards;
}

.check-nav input[type=checkbox]:checked + label {
    color: var(--text);
    -webkit-animation: check-anim-move 0.3s ease 0.1s forwards;
    animation: check-anim-move 0.3s ease 0.1s forwards;
}

.check-nav input[type=checkbox]:checked + label::before {
    background: transparent;
    /*-webkit-animation: check-anim-slice 0.4s ease forwards;*/
    /*animation: check-anim-slice 0.4s ease forwards;*/
}

@-webkit-keyframes check-anim-move {
    50% {
        padding-left: 8px;
        padding-right: 0px;
    }
    100% {
        padding-right: 4px;
    }
}

@keyframes check-anim-move {
    50% {
        padding-left: 8px;
        padding-right: 0px;
    }
    100% {
        padding-right: 4px;
    }
}

@-webkit-keyframes check-anim-slice {
    60% {
        width: 100%;
        left: 4px;
    }
    100% {
        width: 100%;
        left: -2px;
        padding-left: 0;
    }
}

@keyframes check-anim-slice {
    60% {
        width: 100%;
        left: 4px;
    }
    100% {
        width: 100%;
        left: -2px;
        padding-left: 0;
    }
}

@-webkit-keyframes check-anim-01 {
    0% {
        width: 4px;
        top: auto;
        transform: rotate(0);
    }
    50% {
        width: 0px;
        top: auto;
        transform: rotate(0);
    }
    51% {
        width: 0px;
        top: 8px;
        transform: rotate(45deg);
    }
    100% {
        width: 5px;
        top: 8px;
        transform: rotate(45deg);
    }
}

@keyframes check-anim-01 {
    0% {
        width: 4px;
        top: auto;
        transform: rotate(0);
    }
    50% {
        width: 0px;
        top: auto;
        transform: rotate(0);
    }
    51% {
        width: 0px;
        top: 8px;
        transform: rotate(45deg);
    }
    100% {
        width: 5px;
        top: 8px;
        transform: rotate(45deg);
    }
}

@-webkit-keyframes check-anim-02 {
    0% {
        width: 4px;
        top: auto;
        transform: rotate(0);
    }
    50% {
        width: 0px;
        top: auto;
        transform: rotate(0);
    }
    51% {
        width: 0px;
        top: 8px;
        transform: rotate(-45deg);
    }
    100% {
        width: 10px;
        top: 8px;
        transform: rotate(-45deg);
    }
}

@keyframes check-anim-02 {
    0% {
        width: 4px;
        top: auto;
        transform: rotate(0);
    }
    50% {
        width: 0px;
        top: auto;
        transform: rotate(0);
    }
    51% {
        width: 0px;
        top: 8px;
        transform: rotate(-45deg);
    }
    100% {
        width: 10px;
        top: 8px;
        transform: rotate(-45deg);
    }
}

/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: #444444;
    color: #f5f5f5;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: 11px;
    right: 60px;

    font-family: "Open Sans", serif;
    text-transform: initial;
    letter-spacing: 1px;
    font-weight: normal;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}
