@font-face {
    font-family: 'Soccer';
    src: local('Soccer'), url(./fonts/soccer.ttf) format('truetype');
}

@font-face {
    font-family: 'Nunito';
    src: local('Nunito'), url(./fonts/Nunito-Bold.ttf) format('truetype');
}

/* Reset CSS */
* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    --main-color: #e5e5e5;
    --strong-color: #f5f5f5;
    --subtitle-color: #cccccc;
    --black-color: #191919;
}

html {
    font-size: clamp(8px, 1vw, 10px);
    scrollbar-width: thin;
    scrollbar-color: #2c2c2c var(--black-color);
    /*overflow-x: hidden;*/
}

html::-webkit-scrollbar {
    width: 8px;
}

html::-webkit-scrollbar-track {
    background-color: var(--black-color);
}

html::-webkit-scrollbar-thumb {
    background-color: #2c2c2c;
}

body {
    color: var(--main-color);
    background-color: var(--black-color);
    font-size: 1.6rem;
    line-height: 2.5rem;
}

strong {
    color: var(--strong-color);
}

h1, h2, h3 {
    font-weight: bold;
}

h3 {
    font-size: 3.3rem;
    font-family: "Nunito";
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

a, a:visited {
    color: #2990ff;
}

a:hover {
    color: #A50044;
}

h1.separator {
    color: #A50044;
    font-size: 5rem;
    line-height: 5rem;
    font-weight: bold;
    letter-spacing: 5px;
    text-align: center;
}

.lang-btn {
    display: flex;
    justify-content: space-around;
    width: 50%;
    margin: 3rem auto 0 auto;
}

@media screen and (max-width: 599px) {
    .lang-btn {
        width: 80%;
    }
}

header {
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 50vh;
}

/* Common Banner and Footer Section Style */
.banner {
    text-align: center;
    padding: 5rem 0 3rem 0;
    background-color: #2c2c2c;
}

.footer {
    padding: 3rem 30%;
    background-color: #111111;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 0 auto;
}

.banner__title {
    font-size: 4rem;
    line-height: 6rem;
    font-family: "Soccer", monospace, serif;
    letter-spacing: 0.3rem;
}

@media screen and (max-width: 799px) {
    .footer {
        padding: 3rem 15%;
    }
}

@media screen and (min-width: 500px) {
    .banner__title {
        font-size: 5rem;
        line-height: 7rem;
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (min-width: 700px) {
    .banner__title {
        font-size: 6rem;
        line-height: 8rem;
    }
}

.banner__text {
    margin: 2rem auto;
    text-align: justify;
    max-width: 90%;
    color: #c5c5c5;
    font-family: monospace, sans-serif, serif;
}

@media screen and (min-width: 500px) {
    .banner__text {
        font-size: 2rem;
    }
}

@media screen and (min-width: 600px) {
    .banner__text {
        max-width: 80%;
    }
}

@media screen and (min-width: 800px) {
    .banner__text {
        max-width: 60%;
    }
}

/* Banner Section Style */
.banner__sub-title {
    font-size: 2rem;
    line-height: 2rem;
    /*background: -webkit-gradient(linear, right top, left top, from(#004D98), color-stop(#A50044), color-stop(#DB0030), to(#EDBB00));
    background: -webkit-linear-gradient(right, #004D98, #A50044, #DB0030, #EDBB00);
    background: -moz-linear-gradient(right, #004D98, #A50044, #DB0030, #EDBB00);
    background: -o-linear-gradient(right, #004D98, #A50044, #DB0030, #EDBB00);
    background: linear-gradient(to left, #004D98, #A50044, #DB0030, #EDBB00);

    background: linear-gradient(90deg, rgba(0,77,152,1) 0%, rgba(165,0,68,1) 20%, rgba(0,77,152,1) 40%, rgba(165,0,68,1) 60%, rgba(0,77,152,1) 80%, rgba(165,0,68,1) 100%);



    background: -moz-linear-gradient(90deg, #004D98 20%, #A50044 20%, #004D98 40%, #A50044 40%, #004D98 60%, #A50044 60%, #004D98 80%, #A50044 80%);
    background: -webkit-linear-gradient(90deg, #004D98 20%, #A50044 20%, #004D98 40%, #A50044 40%, #004D98 60%, #A50044 60%, #004D98 80%, #A50044 80%);
    background: linear-gradient(90deg, #004D98 20%, #A50044 20%, #004D98 40%, #A50044 40%, #004D98 60%, #A50044 60%, #004D98 80%, #A50044 80%);*/

    color: #A50044;
    /*-webkit-background-clip: text;*/
    /*background-clip: text;*/
    /*-webkit-text-fill-color: transparent;*/
    display: inline;
    font-variant: all-small-caps;
    /*font-weight: normal;*/
}

.banner__sub-title span {
    color: #004D98;
    font-weight: bold;
}

.banner__sub-title strong {
    color: #EDBB00;
    font-weight: bold;
}

@media screen and (min-width: 500px) {
    .banner__sub-title {
        font-size: 2.5rem;
        line-height: 2.5rem;
    }
}

@media screen and (min-width: 700px) {
    .banner__sub-title {
        font-size: 3rem;
        line-height: 3rem;
    }
}

/**************************/
/* Timeline Section Style */
/**************************/
.container {
    width: 100%;
    padding-left: 15px;
    padding-right: 25px;
    /*max-width: 1140px;

    margin-left: auto;
    margin-right: auto;*/
}

.timeline {
    position: relative;
}

.timeline__progressbar {
    --width: 2px;
    /*--left: -moz-calc(((15vw / 2) + 15px) - (var(--width) / 2));*/
    /*--left: calc(((15vw / 2) + 15px) - (var(--width) / 2));*/
    --left: -moz-calc(9vw - ((16 / 439) * (100% - 360px)) - (var(--width) / 2));
    --left: calc(9vw - ((16 / 439) * (100% - 360px)) - (var(--width) / 2));
    position: absolute;
    /*left: 374px;*/
    left: var(--left);
    width: var(--width);
    height: 100%;
    background-color: #2c2c2c;
    z-index: -1;
}

.timeline__progressbar::after {
    content: "";
    position: fixed;
    top: 0;
    left: var(--left);
    /*left: 374px;*/
    width: var(--width);
    height: 50vh;
    background: -webkit-gradient(linear, left top, left bottom, from(#004D98), color-stop(#A50044), color-stop(#DB0030), to(#EDBB00));
    background: -webkit-linear-gradient(#004D98, #A50044, #DB0030, #EDBB00);
    background: -moz-linear-gradient(#004D98, #A50044, #DB0030, #EDBB00);
    background: -o-linear-gradient(#004D98, #A50044, #DB0030, #EDBB00);
    background: linear-gradient(#004D98, #A50044, #DB0030, #EDBB00);
}

@media screen and (min-width: 800px) {
    .timeline__progressbar {
        --width: 3px;
        /*--left: -moz-calc(50% - (var(--width) / 2));*/
        /*--left: calc(50% - (var(--width) / 2));*/
        /*--left: -moz-calc(21vw + (75% / 26) - ((100% - 800px) / 52) - (var(--width) / 2));*/
        /*--left: calc(21vw + (75% / 26) - ((100% - 800px) / 52) - (var(--width) / 2));*/
        --left: -moz-calc(24vw - ((24 / 1120) * (100% - 800px)) - (var(--width) / 2));
        --left: calc(24vw - ((24 / 1120) * (100% - 800px)) - (var(--width) / 2));
    }
}

.timeline__block {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 10vw 1fr;
    grid-template-columns: 10vw 1fr;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    -webkit-box-align: start;
    -webkit-align-items: start;
    -moz-box-align: start;
    -ms-flex-align: start;
    align-items: start;
}

timeline__block:first-child {
    padding-top: 5rem;
}

@media screen and (min-width: 800px) {
    .timeline__block {
        -ms-grid-columns: 17vw 10vw 60vw;
        /*grid-template-columns: 1fr 15vh 1fr;*/
        grid-template-columns: 17vw 10vw 60vw;
        /*grid-template-columns: 300px 150px 1fr;*/
    }
}

@media screen and (min-width: 800px) {
    .timeline__block__head {
        -ms-grid-column: 1;
        -ms-grid-column-span: 1;
        grid-column: 1 / 2;
        text-align: right;
        position: -webkit-sticky;
        position: sticky;
        top: 50vh;
    }
}

.timeline__block__body {
    grid-column: 2 / -1;
}

@media screen and (min-width: 800px) {
    .timeline__block__body {
        grid-column: 3 / -2;
    }
}

.timeline__block__bullet-point {
    position: -webkit-sticky;
    position: sticky;
    top: 50vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media screen and (min-width: 800px) {
    .timeline__block__bullet-point {
        -ms-grid-column: 2;
        -ms-grid-column-span: 1;
        grid-column: 2 / 3;
    }
}

.timeline__block__circle {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    border: 4px solid var(--black-color);
    background-color: #f5f5f5;
    border-radius: 50%;
}

.timeline__block__title {
    font-size: 3rem;
    color: #A50044;
}

.timeline__block__subtitle {
    font-size: 1.6rem;
    font-weight: lighter;
    line-height: 1.7rem;
    color: var(--subtitle-color);
    font-style: italic;
    margin-top: 10px;
    padding-left: 5px;
}

@media screen and (max-width: 799px) {
    .timeline__block__title {
        margin-top: -1.5rem;
        margin-bottom: 2rem;
    }
}

@media screen and (min-width: 800px) {
    .timeline__block__title {
        line-height: 0;
        font-size: 2rem;
    }
}

@media screen and (min-width: 1100px) {
    .timeline__block__title {
        font-size: 2.4rem;
    }
}

@media screen and (min-width: 1300px) {
    .timeline__block__title {
        font-size: 3rem;
    }
}

.timeline__block__text {
    font-size: 2rem;
}

@media screen and (min-width: 500px) {
    .timeline__block__text {
        font-size: 1.9rem;
    }
}

@media screen and (min-width: 800px) {
    .timeline__block__text {
        font-size: 1.8rem;
    }
}

.timeline__hider {
    position: -webkit-sticky;
    position: sticky;
    bottom: -2px;
    width: 100%;
    height: 30vh;
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(var(--black-color)));
    background: -webkit-linear-gradient(transparent, var(--black-color));
    background: -moz-linear-gradient(transparent, var(--black-color));
    background: -o-linear-gradient(transparent, var(--black-color));
    background: linear-gradient(transparent, var(--black-color));
    pointer-events: none;
    z-index: 100;
}

/* BRANDS */


/* OVERRIDES */
.bp-lr {
    width: 12% !important;
}
