button.neon {
    --clr: #A50044;
    position: relative;
    background: #666;
    border-radius: 25px;
    color: #e5e5e5;
    text-decoration: none;
    border: none;
    letter-spacing: 0.1rem;
    font-size: 1.3rem;
    padding: 1rem 3rem;
    font-weight: bold;
    transition: 0.2s;
}

button.neon:hover {
    /*letter-spacing: 0.2rem;*/
    /*padding: 1.1rem 3.1rem;*/
    background: var(--clr);
    color: var(--clr);
    animation: box 3s infinite;
    cursor: pointer;
}

button.neon::before {
    border-radius: 25px;
    content: "";
    position: absolute;
    inset: 2px;
    background: var(--black-color);
}

button.neon span {
    position: relative;
    z-index: 1;
}

button.neon i {
    position: absolute;
    inset: 0;
    display: block;
}

button.neon i::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 2px;
    left: 70%;
    top: -2px;
    border: 2px solid var(--clr);
    background: var(--black-color);
    transition: 0.2s;
}

button.neon:hover i::before {
    width: 15px;
    left: 20%;
    animation: move 3s infinite;
}

button.neon i::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 2px;
    left: 20%;
    bottom: -2px;
    border: 2px solid var(--clr);
    background: var(--black-color);
    transition: 0.2s;
}

button.neon:hover i::after {
    width: 15px;
    left: 60%;
    animation: move 3s infinite;
}

@keyframes move {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(5px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes box {
    0% {
        box-shadow: var(--black-color);
    }
    50% {
        box-shadow: 0 0 25px var(--clr);
    }
    100% {
        box-shadow: var(--black-color);
    }
}

/***************************/
/***************************/
/***************************/

.btn-95,
.btn-95 *,
.btn-95 :after,
.btn-95 :before,
.btn-95:after,
.btn-95:before {
    border: 0 solid;
    box-sizing: border-box;
}

.btn-95 {
    -webkit-tap-highlight-color: transparent;
    -webkit-appearance: button;
    background-color: #000;
    background-image: none;
    color: #fff;
    cursor: pointer;
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Open Sans, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 100%;
    font-weight: 900;
    line-height: 1.5;
    margin: 0;
    -webkit-mask-image: -webkit-radial-gradient(#000, #fff);
    text-transform: uppercase;
}

.btn-95:disabled {
    cursor: default;
}

.btn-95:-moz-focusring {
    outline: auto;
}

.btn-95 svg {
    vertical-align: middle;
}

.btn-95 [hidden] {
    display: none;
}

.btn-95 {
    /*background: hsla(0, 0%, 100%, 0.2);*/
    background: transparent;
    background-clip: content-box;
    border-radius: 50%;
    box-sizing: border-box;
    display: block;
    height: 50px;
    -webkit-mask-image: none;
    padding: 0;
    position: relative;
    transition: 0.2s;
    width: 50px;
}

.btn-95:hover {
    background: transparent;
}

.btn-95 svg {
    --size: 24px;
    fill: #fff;
    display: block;
    height: var(--size);
    left: calc(50% - var(--size) / 2);
    position: absolute;
    top: calc(50% - var(--size) / 2);
    width: var(--size);
}

.btn-95:hover svg {
    fill: #000;
}

.btn-95:before {
    border: 5px solid #A50044;
    border-radius: 50%;
    content: "";
    inset: -1px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transform: scale(2);
    transition: 0.2s;
}

.btn-95:hover:before {
    opacity: 1;
    transform: scale(1);
}
